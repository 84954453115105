@import "styles/variables.scss";

.footer {
  padding: $xlarge 0;
  width: 100%;
  bottom: 0;
  left: 0;
  background: $karman100;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-normal;
  font-weight: $font-weight-normal;
  line-height: 18px;
  letter-spacing: -0.25px;

  a {
    font-size: $font-size-normal;
    font-weight: $font-weight-normal;
    line-height: 18px;
    letter-spacing: -0.25px;
    text-decoration: none;
    color: $white;

    &:hover {
      text-decoration: underline;
    }
  }
}
