@import "styles/variables.scss";

.duplicateUploaderProgressOffset {
  right: 600px;
}

.progressOffset {
  right: 200px;
}

.uploadProgress {
  opacity: 0;
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  background: white;
  width: 100%;
  border: 1px solid $karman20;
  border-radius: $small;

  .uploadHeader {
    display: flex;
    color: $karman100;

    .uploadHeaderStart {
      display: flex;
      flex-direction: column;
      flex: 5;
      align-items: flex-start;
    }
    .uploadHeaderEnd {
      cursor: pointer;
    }
  }

  .uploadBody {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .uploadItemRow {
      font-weight: 500;
      color: black;
      border-bottom: 1px solid rgba(211, 212, 213, 1);
      display: flex;
      flex-direction: row;
      padding: 8px 16px;
      align-items: center;
      height: 46px;

      .rowIcon {
        margin-right: 8px;
      }

      .rowStart {
        display: flex;
        flex-direction: column;
        flex: 5;
        align-items: flex-start;

        .title {
          font-size: 14px;
        }

        .subTitle {
          font-size: 12px;
          color: $karman50;
        }

        .progress {
          width: 100%;
          margin: 6px 0 0;

          .progressBar {
            background-color: rgba(120, 120, 128, 0.2);
            border-radius: 3px;
            padding: 0;
          }

          .progressBar > div {
            background-color: $success;
            width: 0%; /* Adjust with JavaScript */
            height: 3px;
            border-radius: 10px;
          }
        }

        .progressText {
          color: #959aa6;
          font-size: 12px;
          font-weight: 500;
        }
      }

      .rowEnd {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        text-align: right;
        color: $success;
        width: 24px;
        align-items: center;
        vertical-align: center;
        padding: 0 6px;

        .largeLoader {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
}

.isVisible {
  display: flex;
  // -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
  //   both;
  // animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  -webkit-animation: slide-in-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-6-14 18:25:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-12-9 14:52:19
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
