@import "styles/variables.scss";

.status {
  height: 24px;
  padding-top: $medium;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardSimple {
  width: 320px;
  border: 1px solid #d3d4d5;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  display: block;
  padding: $large;

  &.clickable {
    cursor: pointer;
  }

  &.clickable:hover {
    border-color: $neptune;
  }

  &.selected {
    background-color: $neptune04;
    border-color: $neptune;
  }

  .cardSimpleContent {
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 18px;
      margin-bottom: 0;
    }

    .simpleTitle {
      font-size: 18px;
      color: $karman100;
      margin-bottom: $small;
    }

    .cardSubtitle {
      color: $karman100;
      font-size: $font-size-normal;
      text-align: left;

      p {
        margin-bottom: 0;
      }
    }
  }

  .cardDescription {
    font-size: 14px;
    color: $success;
  }

  .cardRadioButton {
    position: absolute;
    top: $large;
    right: $medium;
  }
}

.cardDoc {
  width: 208px;
  height: 260px;
  border: 1px solid #d3d4d5;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  display: block;
  cursor: pointer;
  transform: translate(0px, 0px);

  .cardFooter {
    justify-content: space-between;
  }

  &.isMutating {
    opacity: 0.4;
  }

  &.folderCard {
    background: $triton;
    box-shadow: none;

    &.isDroppable {
      background: $neptune08;
      border-color: $neptune;
    }

    .cardFooter {
      border-top: none;
      justify-content: center;
    }
  }

  &:hover {
    border-color: $karman70;
  }

  .cardImage {
    border-radius: $small $small 0 0;
    height: 200px;
    overflow: hidden;
    pointer-events: none;

    &.cardAvatar {
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.folderIcon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .placeholder {
      height: 196px;
      font-size: 66px;
      color: $karman90;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.cardPerson {
  width: 208px;
  height: 260px;
  border: 1px solid #d3d4d5;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  display: block;
  cursor: pointer;

  &.cardPersonAccepted {
    box-shadow: inset 0px 4px 0px $neptune90;
  }

  &:hover {
    border-color: $karman70;
  }

  .cardImage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 38px;
    margin-bottom: 8px;
    height: 120px;
    overflow: hidden;
    pointer-events: none;

    .placeholder {
      height: 196px;
      font-size: 66px;
      color: $karman90;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.cardFooter {
  position: relative;
  height: 64px;
  padding: 8px;
}

.cardTitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  // text-overflow: ellipsis;
  // overflow: hidden;
  // white-space: nowrap;
  word-break: break-word;
  color: $karman100;
}

.cardSubtitle {
  color: $karman60;
  font-weight: 500;
  text-align: center;
}

.cardPerson {
  .cardFooter {
    font-size: 14px;
    border-top: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.cardMarketing {
  height: 316px;
  width: 358px;
  border: 1px solid rgba(211, 213, 221, 1);

  &.clickable {
    cursor: pointer;
  }
}

.cardMarketingImage {
  height: 202px;
  width: 358px;
}

.cardMarketingFooter {
  width: 358px;
  text-align: left;
  flex-direction: column;
  padding: 16px;
}

.cardMarketingContent {
  text-align: left;
  flex-direction: column;
  padding: 16px;
}

.cardMarketingTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.25px;
  color: $karman100;
}

.cardMarketingSubtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.25px;
  color: $karman60;
  margin-top: 8px;
}

.cardMarketingOverlay {
  position: absolute;
  right: 0px;
  bottom: 50px;
  left: 0px;
}

.cardList {
  margin-top: 8px;
}

.cardListItem {
  height: 44px;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.cardListItemText {
  margin-left: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.25px;
  color: $karman60;
}

.cardHelp {
  height: 316px;
  width: 358px;
  border: 1px solid rgba(211, 213, 221, 1);

  &.clickable {
    cursor: pointer;
  }
}

.cardHelpContent {
  text-align: left;
  flex-direction: column;
  padding: 16px;
}

.cardHelpTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.25px;
  color: $karman100;
}

.cardHelpSubtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.25px;
  color: $karman60;
  margin-top: 4px;
}

.icon {
  color: $karman90;
  margin-left: 10px;
  font-size: 20px;
}
