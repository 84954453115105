//@charset "utf-8";

// Import Google Font - Inter
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

// COLORS & SIZE
@import "styles/variables.scss";

// TEXT
$text: #4a4a4a;
$link: $info;
$body-family: "Inter", "Helvetica", "Arial", sans-serif;
$title-family: false;
$button-family: false;

p {
  font-size: $font-size-normal;
}

// BREAKPOINTS
$gap: $xlarge;
$tablet: 769px;
$desktop: 960px + (2 * $gap);
$widescreen: 1152px + (2 * $gap);
$fullhd: 1440px + (2 * $gap);
$widescreen-enabled: true;
$fullhd-enabled: false;

// LAYOUT
$section-padding: 3rem 1.5rem;
$section-padding-medium: 6rem 1.5rem;
$section-padding-large: 9rem 1.5rem;

// FORM
$label-color: $karman50;
$label-weight: 500;
$input-border-color: $karman40;
$input-focus-border-color: $neptune;
$input-radius: $small;

// BOX
$box-shadow: none;
$box-radius: $small;
$box-padding: $medium;
.box {
  border: 1px solid $karman40;
}

// COLUMNS
.columns.is-gapless-vertical > .column {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

// NAVBAR
$navbar-item-color: $karman106;
$navbar-item-hover-color: $karman100;
$navbar-item-hover-background-color: transparent;
$navbar-item-active-color: $karman100;
$navbar-tab-active-border-bottom-color: $light;
$navbar-tab-active-background-color: $light;
$navbar-dropdown-arrow: $karman106;
$navbar-dropdown-radius: $small;
$navbar-dropdown-border-top: none;
$navbar-dropdown-boxed-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
$navbar-dropdown-item-hover-color: $karman100;
$navbar-dropdown-item-hover-background-color: $neptune08;
$navbar-tab-active-border-bottom-color: #fff;
$navbar-tab-active-border-bottom-width: $small;
.navbar .navbar-dropdown .navbar-item {
  color: $karman100;
  font-weight: 400;
}

// DROPDOWN
$dropdown-item-color: $karman100;
$dropdown-item-hover-color: $karman100;
$dropdown-item-hover-background-color: $neptune08;
$dropdown-content-padding-top: $medium;
$dropdown-content-padding-bottom: $medium;
$dropdown-content-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
$dropdown-content-radius: $small;
$dropdown-menu-min-width: 220px;

// BREADCRUMB
$breadcrumb-item-color: $karman60;
$breadcrumb-item-hover-color: $karman100;
$breadcrumb-item-separator-color: $karman60;

// CARD
$card-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
.card .dropdown {
  top: $small;
  right: $small;
}

// TABS
$tabs-border-bottom-color: $karman20;
$tabs-border-bottom-width: 0;
$tabs-link-padding: 4px 16px 8px;
$tabs-link-color: $karman60;
$tabs-link-active-color: $karman100;
$tabs-link-active-border-bottom-color: $karman100;
.tabs a {
  font-size: 14px;
  font-weight: $font-weight-bold;
}
.tabs li.is-active a {
  padding-bottom: $small;
  border-bottom-width: $small;
}
.tabs a.is-wide {
  width: 168px;
}

// NOTIFICATIONS
$notification-radius: $xlarge;

// TAGS
$tag-background-color: #fff;
$tag-color: $info;
.tag {
  border: 1px solid $info;
  font-weight: 500;
}
$tag-radius: $large;

// HERO
$hero-body-padding: $xlarge $large;

// SPACING HELPERS (.mt-3, .mx-auto, .p-5, etc)
$spacing-shortcuts: (
  "margin": "m",
  "padding": "p",
);
$spacing-directions: (
  "top": "t",
  "right": "r",
  "bottom": "b",
  "left": "l",
);
$spacing-horizontal: "x";
$spacing-vertical: "y";
$spacing-values: (
  "0": 0,
  "1": $small,
  "2": $medium,
  "3": $large,
  "4": $xlarge,
  "5": $xxlarge,
  "auto": auto,
);

.is-neptune {
  background-color: $neptune !important;
  color: $white !important;
}

.has-text-neptune,
.button.has-text-neptune {
  color: $neptune;
}

.has-text-karman50 {
  color: $karman50;
}

.has-text-karman90 {
  color: $karman90;
}
.has-text-karman100 {
  color: $karman100;
}

//Buttons
$button-disabled-opacity: 1;
$button-disabled-border-color: $karman30;
$button-border-color: $neptune;
.is-blue,
.button.is-blue {
  background: $neptune;
}
.button.has-text-blue,
.has-text-blue {
  color: $neptune;
}
.is-btnBlue,
.button.is-btnBlue {
  background: $neptune;
}
.has-text-btnBlue {
  color: $neptune;
}
.button.is-btnBlue[disabled],
.button.is-btnBlue[disabled]:hover {
  background: $karman30;
}
.is-btnBlue:hover,
.button.is-btnBlue:hover {
  background: $neptuneHover;
}
//outlined
.button.is-btn-outlined {
  background: $white;
  color: $neptune;
  border: 1px solid $neptune;
}
.button.is-btn-outlined[disabled] {
  color: $karman30;
  border-color: $karman30;
  background: $karman5;
}
.is-btn-outlined:hover,
.button.is-btn-outlined:hover,
.is-btn-outlined:active,
.button.is-btn-outlined:active,
.is-btn-outlined:focus,
.button.is-btn-outlined:focus {
  color: $neptuneHover;
  background: $karman5;
  border-color: $neptuneHover;
}
//text
.button.is-btn-text {
  background: none;
  color: $neptune;
  border: none;
}
.button.is-btn-text[disabled] {
  color: $karman30;
  border-color: $karman30;
}
.is-btn-text:hover,
.button.is-btn-text:hover,
.is-btn-text:active,
.button.is-btn-text:active,
.is-btn-text:focus,
.button.is-btn-text:focus {
  color: $neptuneHover;
  background: $karman5;
}

.has-text-size-normal,
.label.has-text-size-normal {
  font-size: $font-size-normal;
}

.has-text-gray100 {
  color: $gray100;
}

.has-bottom-border-gray {
  border-bottom: 1px solid $karman20;
}

.has-text-karman100 {
  color: $karman100;
}

.is-font-size-large {
  font-size: $font-size-large;
}

// SEE DOCS FOR MORE:
// https://bit.ly/30UvE5O

// IMPORT BULMA
@import "~bulma/bulma.sass";

// IMPORT MOBILE STYLES
@import "styles/mobile.scss";

body {
  // Fix Bulma bug where columns cause
  // horizontal scroll on mobile.
  // https://github.com/jgthms/bulma/issues/449
  // overflow-x: hidden;

  //STACH - I have to remove this in order for sticky functionality to work.
}

#root {
  // Full height of screen and flex so that we can push footer
  // to the bottom even if content is shorter than screen
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // Push footer to the bottom of the screen
  > .footer {
    margin-top: auto;
  }
  // Prevent child elements from shrinking when content
  // is taller than the screen (quirk of having flex parent)
  > * {
    flex-shrink: 0;
  }
}

//Overrides that do not have a corresponding sass variable

body {
  background: $white;
}

//Modals
.card-header {
  box-shadow: none;
}

.modal-card-head,
.modal-card-foot {
  background: #fff;
  border: none;
}

.modal-card-head {
  padding: $large;
}
.modal-card-body {
  overflow: visible;
}
.modal-card-foot {
  justify-content: space-between;
}

// Typography
h1,
h2,
h3,
h4,
h5,
p {
  color: $karman100;
}

h1 {
  font-size: $xlarge;
  font-style: normal;
  font-weight: $font-weight-bold;
  line-height: 40px;
  letter-spacing: -0.5px;
}

h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: $font-weight-bold;
  line-height: $xlarge;
  letter-spacing: -0.25px;
}

h3 {
  font-size: 18px;
  font-style: normal;
  font-weight: $font-weight-bold;
  line-height: 26px;
  letter-spacing: -0.25px;
}

.button {
  border-radius: 36px;
  font-weight: $font-weight-bold;
  border: 1px solid $neptune;
}

.button.is-small {
  height: 32px;
  border-radius: 32px;
  line-height: 32px;
  font-size: $font-size-normal;
}

.button.is-medium {
  height: $input-height-normal;
  line-height: $input-height-normal;
  font-size: $font-size-normal;
}

.button.is-large {
  height: 56px;
  border-radius: 56px;
  line-height: 56px;
  font-size: $font-size-normal;
}

.field:not(:last-child) {
  margin-bottom: 0;
}

.label:not(:last-child) {
  margin-bottom: 2px;
}

.input.is-small {
  height: $input-height-small;
  font-size: $font-size-normal;
  border-radius: $small;
}

.input.is-normal {
  height: $input-height-normal;
  font-size: $font-size-large;
  font-weight: 500;
  color: $karman100;
}

.input-description {
  font-size: $font-size-small;
  color: $karman50;
}

.breadcrumb li + li::before {
  content: "";
}

hr {
  background: $karman20;
  height: 1px;
}

.title {
  color: $karman100;
}

// TOAST NOTIFICATIONS

.Toastify__toast-container.toastify-notification {
  width: 500px;
  top: 64px;

  .Toastify__toast {
    font-family: $body-family;
    font-weight: $font-weight-normal;
    font-size: $font-size-normal;
    min-height: $xlarge;
    text-align: center;
    border-radius: $xlarge;
    padding: 0;
    color: $white;
  }
  .Toastify__toast--dark {
  }
  .Toastify__toast--default {
  }
  .Toastify__toast--info {
    background: $info;
  }
  .Toastify__toast--success {
    background: $success;
  }
  .Toastify__toast--warning {
    background: $warning;
  }
  .Toastify__toast--error {
    background: $danger;
  }
  .Toastify__toast-body {
  }
}

@media screen and (min-width: 480px) and (max-width: 1023px) {
  .columns {
    padding: 0;
  }
  .container {
    padding: $large;
  }
}

@media screen and (max-width: 479px) {
  .Toastify__toast-container.toastify-notification {
    width: 100%;
    padding: 0px $medium;
  }
  .column {
    justify-content: center;
  }
  .container {
    padding: $medium;
  }
  .card {
    width: auto !important;
  }
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
  }

  .tabs a.is-wide {
    width: 125px;
  }
}
