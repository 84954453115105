//Colors
$white: #fff;
$black: #000;
$primary: #ee9944;
$info: #00b1d1;
$success: #00c67a;
$warning: #ffb761;
$danger: #ff5555;
$dangerHover: #ee3333;
$light: #f5f5f5;
$dark: #212a2f;
$gray: #99a4b9;
$gray5: #f8f8f8;
$gray100: #212a2f;
$btnBlue: #1b82ae;

//Size
$small: 4px;
$medium: 8px;
$large: 16px;
$xlarge: 32px;
$xxlarge: 64px;

//Font
$font-weight-light: 300;
$font-weight-normal: 500;
$font-weight-bold: 700;
$font-size-small: 10px;
$font-size-normal: 14px;
$font-size-large: 16px;

//Input
$input-height-small: 36px;
$input-height-normal: 44px;

//New Colors
$solar: #f7941d;
$redShift: #ec5024;
$neptune: #2742f5;
$neptune90: rgba(39, 66, 245, 0.9);
$neptune60: rgba(39, 66, 245, 0.6);
$neptuneHover: #1f34c4;
$neptune04: rgba(39, 66, 245, 0.04);
$neptune08: rgba(39, 66, 245, 0.08);
$karman100: #212c55;
$karman106: rgba(33, 44, 85, 0.6);
$karman90: #374166;
$karman80: #4d5677;
$karman70: #646b88;
$karman60: #7a8099;
$karman50: #9096aa;
$karman40: #a6abbb;
$karman30: #bdc0cc;
$karman20: #d3d5dd;
$karman10: #e9eaee;
$karman5: #fbfbfc;
$triton: #f9f9ff;

//Breakpoints
$small-breakpoint: 480px;
$medium-breakpoint: 768px;
$large-breakpoint: 1023px;
