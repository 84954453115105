@import "styles/variables.scss";

.background {
  background-color: $white;
}

.logo {
  width: 118px;
  margin-top: 48px;
  margin-bottom: 40px;
}

.headerWrapper {
  width: "100%";
  display: flex;
  justify-content: center;
}

.logoWrapper {
  width: 326px;
}

.metaText {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.25px;
  color: $karman100;
  width: 325px;
  text-align: center;
  margin-bottom: 16px;
}

.sharedMetaText {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.25px;
  color: $karman100;
  width: 540px;
  text-align: center;
  margin-bottom: 16px;
}

.metaWrapper {
  width: "100%";
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.securityText {
  max-width: 420px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $karman70;
  margin-bottom: 32px;
}

.sendCodeButtonWrapper {
  width: 326px;
}

.accessFilesButtonWrapper {
  width: 326px;
  margin-top: 48px;
  margin-bottom: 48px;
}

.errorWrapper {
  width: 326px;
}

.errorHeader {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.25px;
  color: $karman100;
  text-align: left;
  margin-bottom: 8px;
}

.subText {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.25px;
  color: $karman70;
  width: 326px;
}

.keepOrganizedHeader {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.25px;
  color: $karman100;
  text-align: left;
  margin-bottom: 8px;
  margin-top: 64px;
}

.learnMoreButtonWrapper {
  width: 326px;
  margin-top: 32px;
}

.footerImage {
  margin-top: 64px;
  border-top: 1px solid $karman20;
}

.sendCodeButton {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $neptune;
  cursor: pointer;
}

.sendCodeButtonDisabled {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
