@import "styles/variables.scss";

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $karman5;
  border-radius: 50%;
  border: 0.5px solid $karman50;
  position: relative;

  &.isClickable {
    cursor: pointer;
    &:hover {
      border-color: $karman70;

      .childIcon {
        color: $karman70;
      }
    }
  }

  &.small {
    height: 24px;
    width: 24px;
  }

  &.medium {
    height: 36px;
    width: 36px;
  }

  &.large {
    height: 48px;
    width: 48px;
  }

  &.xlarge {
    height: 80px;
    width: 80px;
  }

  &.xxlarge {
    height: 120px;
    width: 120px;
  }

  .icon {
    color: $karman90;

    &.small {
      font-size: 16px;
    }

    &.medium {
      font-size: 24px;
    }

    &.large {
      font-size: 32px;
    }

    &.xlarge {
      font-size: 48px;
    }

    &.xxlarge {
      font-size: 64px;
    }
  }

  .childIcon {
    position: absolute;
    color: $karman40;
    bottom: 0;
    margin-bottom: 4px;
  }
}
