@import "styles/variables.scss";

.actionBtn {
  min-width: 120px;
  background: $neptune;
  color: #fff;
  border-radius: 56px;
  padding: 0 $xlarge;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background: $neptuneHover;
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    background: $karman30;
  }

  &.secondary {
    background: #fff;
    border: 1px solid $neptune;
    color: $neptune;

    &:hover,
    &:focus,
    &:active {
      background: $gray5;
    }

    &:disabled,
    &[disabled] {
      background: $gray5;
      color: $karman30;
      border-color: $karman30;
    }
  }

  &.danger {
    background: $danger;
    border: 1px solid $danger;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background: $dangerHover;
    }

    &:disabled,
    &[disabled] {
      background: $danger;
      color: $karman30;
      border-color: $danger;
    }
  }

  &.dangerInverse {
    background: $white;
    border: 1px solid $danger;
    color: $danger;

    &:hover,
    &:focus,
    &:active {
      background: $white;
      border-color: $dangerHover;
      color: $dangerHover;
    }

    &:disabled,
    &[disabled] {
      background: $karman30;
      color: $danger;
      border-color: $karman30;
    }
  }

  .btnText {
    font-weight: 700;
    font-size: 14px;
  }
}
