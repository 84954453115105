@import "styles/variables.scss";

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;

  .background {
    background-color: $white;

    &.isUploading {
      margin-bottom: 100px;
    }

    h2 {
      text-align: center;
    }

    p {
      font-weight: $font-weight-normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: $karman70;
      text-align: center;
    }

    a {
      color: $neptune;
    }

    .box {
      border: 1px dashed $karman70;
      border-radius: $medium;
      width: 600px;
      min-height: 334px;
      background: $triton;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: $large 0;
      overflow: hidden;
      transition: background-color 0.3s, border-color 0.3s;

      .uploadDescription {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .uploadHeader {
          color: $neptune;
          margin: 0 0 $large 0;
        }
      }

      button {
        opacity: 1;
        transition: opacity 0.3s;
      }

      &.isUploading {
        justify-content: flex-start;
      }

      &.dragActive {
        border: 1px solid $neptune;
        background-color: $neptune08;

        button {
          opacity: 0.3;
        }
      }
    }

    .info {
      margin: $medium 0;
      text-align: left;
    }

    .uploader {
      margin: $large 0;
    }
  }
}

.logo {
  width: 118px;
  margin-top: 48px;
  margin-bottom: 40px;
}

.headerWrapper {
  width: "100%";
  display: flex;
  justify-content: center;
}

.logoWrapper {
  width: 326px;
}

.metaText {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.25px;
  color: $karman100;
  width: 325px;
  text-align: center;
  margin-bottom: 16px;
}

.sharedMetaText {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.25px;
  color: $karman100;
  width: 540px;
  text-align: center;
  margin-bottom: 16px;
}

.metaWrapper {
  width: "100%";
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.securityText {
  max-width: 420px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $karman70;
  margin-bottom: 32px;
}

.sendCodeButtonWrapper {
  width: 326px;
}

.accessFilesButtonWrapper {
  width: 326px;
  margin-top: 48px;
  margin-bottom: 48px;
}

.errorWrapper {
  width: 326px;
}

.errorHeader {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.25px;
  color: $karman100;
  text-align: left;
  margin-bottom: 8px;
}

.subText {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.25px;
  color: $karman70;
  width: 326px;
}

.keepOrganizedHeader {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.25px;
  color: $karman100;
  text-align: left;
  margin-bottom: 8px;
  margin-top: 64px;
}

.learnMoreButtonWrapper {
  width: 326px;
  margin-top: 32px;
}

.footerImage {
  margin-top: 64px;
  border-top: 1px solid $karman20;
}

.sendCodeButton {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $neptune;
  cursor: pointer;
}

.sendCodeButtonDisabled {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.dragFileElement {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
