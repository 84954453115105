@import "styles/variables.scss";

.formFieldPasswordButton {
  position: absolute;
  right: 3px;
  top: 2px;
  border: none;
  color: #808694;

  &:hover,
  &:active,
  &:focus {
    color: $karman100;
    border: none;
    box-shadow: none;
  }
}

.description {
  color: $karman50;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.25px;
  margin-top: 4px;
}

.errorDescription {
  color: $danger;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.25px;
}

.fieldMargin {
  margin-bottom: $xlarge !important;
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .removeText {
    cursor: pointer;
    position: absolute;
    right: $medium;
    color: $karman40;
    height: 24px;

    &:hover {
      color: $karman70;
    }
  }
}

.belowInput {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.iconWrapper {
  height: 36px;
  width: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.actionIcon {
  color: $neptune;
}
