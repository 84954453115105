@import "styles/variables.scss";

.icon-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  padding: 25px 0 0;
}

.small {
  font-size: 16px;
}

.medium {
  font-size: 24px;
}

.large {
  font-size: 48px;
}

.xlarge {
  font-size: 64px;
}

.xxlarge {
  font-size: 96px;
}

.karman90 {
  color: $karman90;
}

.info {
  color: $neptune;
}
