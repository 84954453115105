@import "styles/variables.scss";

.navbar-item,
.navbar-link {
  @media screen and (max-width: $large-breakpoint) {
    color: $karman100;
  }
}

a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover,
a.navbar-item.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
.navbar-link.is-active {
  @media screen and (max-width: $large-breakpoint) {
    color: $karman100;
  }
}

.columns {
  @media screen and (min-width: $small-breakpoint) and (max-width: $large-breakpoint) {
    //justify-content: center;
    padding: 0px $xxlarge;
  }
}

#navbar-account-dropdown-btn {
  @media screen and (max-width: $large-breakpoint) {
    display: none;
  }
}
