@import "styles/variables.scss";

.link {
  color: $neptune;
  font-size: $font-size-normal;
  font-weight: 500;
  display: flex;
  align-items: center;

  &:hover {
    color: $neptune;
  }

  .icon {
    color: $neptune;
    padding-left: $large;
  }
}
